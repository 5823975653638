
h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

.product-title {
  font-weight: bold;
  font-size: 1.2em;
}

.product-id {
  font-size: 0.8em;
  margin: 1em 0;
}

.product-edit-icon {
  opacity: 0.7;
  position: absolute;
  right: 2.3rem;
  top: 0.4rem;
}

.product-edit-icon:hover {
  opacity: 1;
}

.navbar,
.navbar-end,
.navbar-menu,
.navbar-start {
  -webkit-align-items: stretch !important;
          align-items: stretch !important;
  display: -webkit-flex !important;
  display: flex !important;
}

.navbar-menu {
  -webkit-flex-grow: 1 !important;
          flex-grow: 1 !important;
  -webkit-flex-shrink: 0 !important;
          flex-shrink: 0 !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.navbar-item {
  display: -webkit-flex !important;
  display: flex !important;
}

.navbar-end {
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
  margin-left: auto !important;
}

.menu-border {
  border-style: solid;
  border-width: 0px;
}

.features {
  padding-bottom: 3em;
}

body {
  visibility: visible !important;
}

.auth form {
  margin-top: 1em;
  max-width: 50%;
}

.buttons {
  margin-left: 1em;
}

.column {
  float: left;
  width: 90%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: #d1d119 !important;
}

.bg-1 {
  background-color: #dcecec; /* Dark Blue */
  color: #0f0e0e;
}
.bg-2 {
  background-color: #dee4e2; /* Green */
  color: #020202;
}
.bg-3 {
  background-color: #ffffff; /* White */
  color: #555555;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup,
.dropdown {
  position: relative;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.form-horizontal .control-label {
  text-align: left;
}

.btn:hover {
  color: black;
  text-decoration: none;
  background-color: white;
}

.content.has-text-centered {
  /* visibility: hidden; */
  color: white;
}

body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  -webkit-filter: url(#gaussian-blur);
          filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -ms-align-items: center;
  -webkit-align-items: center;
          align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

